

$green: #176600;
$darkgreen: darken($green, 10%);
$brown: #664700;
$darkbrown: darken($brown, 10%);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
}

body {
    width: 100%;
    margin: 0 auto;
    background-color: lightgrey;
}

.content {
    max-width: 960px;
    margin: auto;
    height: auto;
}

header, main {
    width: 100%;
    overflow: auto;
}

header h2 {
    display: inline-block;
    color: white;
    margin-right: 20px;

    a {
        color: white;
        text-decoration: none;
    }
}

header {
    padding: 10px 20px;
    background-color: $darkgreen;
    color: white;
}

main {
    background-color: white;
}

article {
    margin: 20px 20px;
}

.site-nav-bar {
    display: inline-block;

    ul {
        display: inline-block;
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        text-align: center;
    }

    a {
        display: inline-block;
        padding: 5px;

        color: white;
        text-decoration: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $green;
}

h1, h2, h3, h4, h5, h6, p, ul {
    margin: 10px 0;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 20px;
}

a {
    text-decoration: none;

    &:hover {
	text-decoration: underline;
    }
}

blockquote {
    color: #666666;
    margin-left: 20px;
    padding: 10px 0 10px 10px;
    border-left: solid black 1px;

    footer {
        text-align: right;
        margin-right: 10%;
    }
}

ul {
    padding-left: 40px;
}

p {
    line-height: 1.3;
    margin: .5em 0;
}

.project-listing {
    margin: 10px 0;
}

.project-item {
    margin: 20px 0;
}
